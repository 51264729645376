
















































import Vue from "vue";
import Component from "vue-class-component";
import { Icon, Cell } from "vant";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    [Cell.name]: Cell,
    [Icon.name]: Icon
  }
})
export default class DeviceBattery extends Vue {
  @Prop() battery!: Battery;
  @Prop() order!: {};
}
