




















import Vue from "vue";
import Component from "vue-class-component";
import { Icon, Cell } from "vant";
import { Prop } from "vue-property-decorator";
// import DeviceBattery from "./battery.vue";

@Component({
  components: {
    // "device-battery": DeviceBattery,
    [Cell.name]: Cell,
    [Icon.name]: Icon
  }
})
export default class DeviceBike extends Vue {
  @Prop() bike!: {};
  @Prop() order!: {};
}
