




































































































import Vue from "vue";
import Component from "vue-class-component";
import { Steps, Step, Button, Cell, CellGroup, Icon, Toast, Dialog, Field, Popup, SubmitBar, Image, PullRefresh } from "vant";
// import OrderDevice from "./order-device.vue";
import DeviceBattery from "./battery.vue";
import DeviceBike from "./bike.vue";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    // "order-device": OrderDevice,
    "device-battery": DeviceBattery,
    "device-bike": DeviceBike,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [SubmitBar.name]: SubmitBar,
    [Image.name]: Image,
    [Dialog.Component.name]: Dialog.Component,
    [PullRefresh.name]: PullRefresh
  }
})
export default class UserDevice extends Vue {
  userId = 0;
  user = {} as User;
  orders = [{ id: 0, batteryList: [] }];
  batteries = [];

  pick = {
    list: [] as Battery[],
    show: false,
    ready: false,
    text: "请选择电池",
    act: "",
    order: { id: 0 }
  };

  dialog = {
    show: false,
    list: [] as Battery[],
    orders: [{ batteryList: [] }]
  };

  info = {
    show: false
  };

  batteryAdd = {
    show: false,
    id: "",
    orderId: 0
  };

  isRefreshing = false;

  data() {
    return {
      userId: parseInt(this.$route.params.id) || 0
    };
  }
  created() {
    this.$readyWx(this.$axios, ["scanQRCode"]);
    this.loadUserDeviceInfo().then(() => {
      if (this.$route.query.startReplace) {
        this.startReplace();
      }
    });
  }
  mounted() {
    if (!localStorage.myCacheUserDeviceInfoRead) {
      this.info.show = true;
    }
  }
  onRefresh() {
    this.loadUserDeviceInfo().then(() => {
      this.isRefreshing = false;
    });
  }
  // ---------- functions
  loadUserDeviceInfo() {
    return this.$axios.post("/api/manage/getUserDeviceInfo", { userId: this.userId }).then(e => {
      const data = e.data.data;
      this.user = data.user;
      this.orders = data.list;
      this.batteries = data.batteryList;
    });
  }
  showBatteryAdd(order: { id: number }) {
    this.batteryAdd.show = true;
    this.batteryAdd.orderId = order.id;
  }
  addBattery() {
    const commData = { isFee: 0, fee: 0, mark: "sys" };
    this.$axios.post("/api/manage/batteryPlanOrderBatterySwitchMulti", Object.assign({}, commData, { planOrderId: this.batteryAdd.orderId, oldBatteryIds: -1, newBatteryIds: this.batteryAdd.id.trim() })).then(() => {
      Toast.success("电池已添加到订单");
      this.loadUserDeviceInfo();
    });
  }
  addBatteryScan() {
    this.$scanCode((content: string) => {
      this.batteryAdd.id = content;
    });
  }
  pickBattery(battery: Battery, order: { id: number }) {
    const pos = this.pick.list.findIndex(b => b.id == battery.id);
    if (pos != -1) {
      this.pick.list.splice(pos, 1);
      return;
    }
    if (battery.replaceOrderId > 0) {
      Dialog.confirm({
        message: "该电池上还有未完成的换电订单",
        confirmButtonText: "关闭",
        cancelButtonText: "查看",
        cancelButtonColor: "green"
      })
        .then(() => {
          // close
        })
        .catch(() => {
          this.$toUrl("/web/manager/battery_replace_order_detail?exchange_order_id=" + battery.replaceOrderId);
        });
      return;
    }
    this.pick.order = order;
    const len = this.pick.list.length;
    if (len > 1 || len == 0) {
      this.pick.list = [battery];
    } else if (len == 1) {
      const b = this.pick.list[0];
      if (b.userId == 0 || battery.userId == 0) {
        this.pick.list = [battery];
      } else if (b.userId != battery.userId) {
        // 交换
        this.pick.list.push(battery);
      } else {
        this.pick.list = [battery];
      }
    }
  }
  onInfoClose() {
    localStorage.myCacheUserDeviceInfoRead = true;
  }
  @Watch("pick.list")
  onPickListChange(list: Battery[]) {
    const len = list.length;
    if (len > 0) {
      this.pick.show = true;
      if (len > 2) {
        this.pick.act = "";
        this.pick.text = "无法操作";
        this.pick.ready = false;
      } else if (len == 2) {
        this.pick.act = "switch";
        this.pick.text = "交换选中的电池";
        this.pick.ready = true;
      } else if (len == 1) {
        const battery = list[0];
        if (battery.userId == 0) {
          this.pick.act = "add";
          this.pick.text = "电池添加到订单";
          this.pick.ready = true;
        } else if (battery.userId != this.user.id) {
          this.pick.act = "";
          this.pick.text = "无法操作";
          this.pick.ready = false;
        } else {
          this.pick.act = "remove";
          this.pick.text = "电池从订单删除";
          this.pick.ready = true;
        }
      }
    } else {
      this.pick.show = false;
    }
  }
  onPickDone() {
    const battery = this.pick.list[0];
    const commData = { isFee: 0, fee: 0, mark: "sys" };
    let p;
    switch (this.pick.act) {
      case "switch":
        this.dialog.show = true;
        this.dialog.list = this.pick.list;
        p = Promise.resolve();
        break;
      case "add":
        p = Dialog.confirm({
          message: "您确定要将该电池添加到当前的订单吗?"
        }).then(() => {
          this.$axios.post("/api/manage/batteryPlanOrderBatterySwitchMulti", Object.assign({}, commData, { planOrderId: this.pick.order.id, oldBatteryIds: -1, newBatteryIds: battery.id })).then(() => {
            Toast.success("电池已添加到订单");
            this.loadUserDeviceInfo();
          });
        });
        break;
      case "remove":
        p = Dialog.confirm({
          message: "您确定要将该电池从当前的订单移除吗?"
        }).then(() => {
          this.$axios.post("/api/manage/batteryPlanOrderBatterySwitchMulti", Object.assign({}, commData, { planOrderId: battery.planOrderId, oldBatteryIds: battery.id, newBatteryIds: -1 })).then(() => {
            Toast.success("电池已从订单移除");
            this.loadUserDeviceInfo();
          });
        });
        break;
      default:
        Toast.fail("错误");
        return;
    }
    p.then(() => {
      this.pick.list = [];
    });
  }
  @Watch("dialog.show")
  onDialogShow(show: boolean) {
    this.dialog.orders = [];
    if (!show) {
      return;
    }
    for (const battery of this.dialog.list) {
      this.$axios.post("/api/manage/getBatteryPlanOrderInfoForSwitch", { batteryId: battery.id }).then(e => {
        this.dialog.orders.push(e.data.data);
      });
    }
  }
  startReplace() {
    Dialog.confirm({
      message: "请确认用户租售订单电池信息正常无误后, 继续换电操作",
      confirmButtonText: "取消",
      confirmButtonColor: "black",
      cancelButtonText: "确认",
      cancelButtonColor: "green"
    }).catch(() => {
      let planOrderId = 0;
      if (this.orders.length < 1) {
        Toast.fail("用户无可用租售订单");
        return;
      } else {
        planOrderId = this.orders[0].id;
      }
      this.$router.push("/battery-plan-order/replace-manual/" + planOrderId);
    });
  }
  switchBattery() {
    const list = this.dialog.list;
    if (list.length != 2) {
      Toast.fail("数据错误");
      return;
    }
    let planOrderId = 0;
    let oldBatteryId = 0;
    let newBatteryId = 0;
    for (const battery of list) {
      if (battery.userId == this.user.id) {
        planOrderId = battery.planOrderId;
        oldBatteryId = battery.id;
      } else {
        newBatteryId = battery.id;
      }
    }
    if (planOrderId == 0) {
      Toast.fail("数据异常");
      return;
    }
    const postData = {
      planOrderId: planOrderId,
      oldBatteryIds: oldBatteryId,
      newBatteryIds: newBatteryId,
      isFee: 0,
      fee: 0,
      mark: "sys"
    };
    this.$axios.post("/api/manage/batteryPlanOrderBatterySwitchMulti", postData).then(() => {
      Toast.success("电池已交换");
      this.loadUserDeviceInfo();
    });
  }
}
